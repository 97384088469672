import request from "@/util/request";
export function login(data){
    return request({
        url:"/api/company/account/login",
        method:"post",
        params:data,
        token_type:"company"
    })
}
export function updateCompany(data){
    return request({
        url:"/api/company/save",
        method:"post",
        token_type:"company",
        params:data
    })
}
export function publishCompanyPost(data){
    return request({
        url:"/api/company/publishCompanyPost",
        method:"post",
        token_type:"company",
        params:data
    });
}

export function updateCompanyPost(data){
    return request({
        url:"/api/company/updateCompanyPost",
        method:"post",
        token_type:"company",
        params:data
    });
}
export function sendResetPasswordMsg(){
    return request({
        url:"/api/company/account/restPasswordSendMsg",
        method:"post",
        token_type:"company"
    })
}
export function resetPassword(data){
    return request({
        url:"/api/company/account/restPassword",
        method:"post",
        token_type:"company",
        params:data
    })
}
export function getWorkExperienceList(){
    return request({
        url:"/api/company/workExperienceList",
        method:"post",
        token_type:"company"
    })
}
export function getWorkStatusList(){
    return request({
        url:"/api/company/workStatusList",
        method:"post",
        token_type:"company"
    })
}
export function getCompanyInfo(){
    return request({
        url:"/api/company/info",
        method:"post",
        token_type:"company"
    })
}
export function getCompanyAccount(){
    return request({
        url:"/api/company/account/info",
        method:"post",
        token_type:"company"
    })
}
export function getCompanyList(){
    return request({
        url:"/api/company/account/list",
        method:"post",
        token_type:"company"
    })
}
export function authentication(){
    return request({
        url:"/api/company/account/authentication",
        method:"post",
        act:"form",
        token_type:"company"
    })
}
export function getCompanyWelfareInit(){
    return request({
        url:"/api/company/getCompanyWelfareInit",
        method:"post",
        token_type:"company"
    })
}
export function getPositionList(){
    return request({
        url:"/api/company/postClassificationList",
        method:"post",
        token_type:"company"
    })
}


export function getJobList(data){
    return request({
        url:"/api/company/companyPostList",
        method:"post",
        token_type:"company",
        params:data
    })
}
export function refreshCompanyToken(){
    return request({
        url:"/api/company/account/refresh/token",
        method:"post",
        token_type:"refresh_company_token",
    })
}
export function uploadImage(data){
    return request({
        url:"/api/company/uploadImage",
        method:"post",
        token_type:"company",
        act:"form",
        params:data
    });
}
export function updateCompanyAccount(data){
    return request({
        url:"/api/company/account/update",
        method:"post",
        token_type:"company",
        params:data
    });
}
export function inviteUserApply(data){
    return request({
        url:"/api/company/inviteUserApply",
        method:"post",
        token_type:"company",
        params:data
    });
}
export function getApplyUserList(data){
    return request({
        url:"/api/company/applyUserList",
        method:"post",
        token_type:"company",
        params:data
    });
}
export function getApplyUserRecommendList(data){
    return request({
        url:"/api/company/applyUserRecommendList",
        method:"post",
        token_type:"company",
        params:data
    });
}
export function getIndustryList(){
    return request({
        url:"/api/company/industryClassificationList",
        token_type:"company",
        method:"post"
    })
}
export function updateApplyUserStatus(data){
    return request({
        url:"/api/company/updateApplyUserStatus",
        method:"post",
        token_type:"company",
        params:data
    })
}
export function getPostDetail(data){
    return request({
        url:"/api/company/companyPostInfo",
        method:"post",
        token_type:"company",
        params:data
    })
}
export function viewResume(data){
    return request({
        url:"/api/company/viewResume",
        method:"post",
        token_type:"company",
        params:data
    })
}

export function jobSelectList(){
    return request({
        url:"/api/company/companyPostSelectList",
        method:"post",
        token_type:"company"
    })
}
