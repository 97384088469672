<template>
  <div class="foot">
    <div class="content">
      <div class="foot_left">
        <div class="foot_logo">
          <img src="../assets/img/bottom_logo.png" style="height:54px;"/>
        </div>
        <dl>
          <dt>工作时间：</dt><dd>09:00-18:00</dd>
        </dl>
        <dl>
          <dt>联系邮箱：</dt><dd>support@eetalent.com.cn</dd>
        </dl>
        <dl>
          <dt>咨询电话：</dt><dd>13371728065 (同微信)</dd>
        </dl>
        <div class="foot_link_list">
          <div class="foot_link">
            <img style="width:23px;height:27px;" src="../assets/img/foot_qq.png"/>
          </div>
          <div class="foot_link">
            <img style="width:28px;height:22px;" src="../assets/img/foot_wechat.png"/>
          </div>
          <div class="foot_link">
            <img style="width:26px;height:26px;" src="../assets/img/foot_weibo.png"/>
          </div>
          <div class="foot_link">
            <img style="width:24px;height:24px;" src="../assets/img/foot_mail.png"/>
          </div>
          <div class="foot_link">
            <img style="width:28px;height:28px;"  src="../assets/img/foot_phone.png"/>
          </div>
        </div>
      </div>
      <div class="foot_right">
        <div class="service">
          <div class="service_title">企业服务</div>
          <template v-for="(item,index) in enterprise"  :key="index">
            <a :href="item.jumpLink" class="service_item">{{ item.title }}</a>
          </template>

        </div>
        <div class="service">
          <div class="service_title">使用与帮助</div>
          <template v-for="(item,index) in usage"  :key="index">
            <a :href="item.jumpLink" class="service_item">{{ item.title }}</a>
          </template>
        </div>
        <div class="service">
          <div class="service_title">友情链接</div>
          <template v-for="(item,index) in friendLink"  :key="index">
            <a :href="item.jumpLink" target="_blank" class="service_item">{{ item.title }}</a>
          </template>
        </div>
      </div>
    </div>
    <div class="split_line"></div>
    <div class="content foot_right">
      ©2018 - 电堂科技版权所有 渝ICP备2020013055号  <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener noreferrer">增值电信业务经营许可证：渝B2-20210112</a>
<!--      <div style="text-align: center;">-->
<!--        ©{{year}} - 嵌入式人才招聘网版权所有-->
<!--      </div>-->

    </div>
  </div>
</template>

<script>
import {getFooterInfo} from "@/api/user/user";

export default {
  name: "foot",
  data(){
    return {
      year:new Date().getFullYear(),
      enterprise:[],
      usage:[],
      friendLink:[]
    }
  },
  mounted(){
    getFooterInfo().then(res => {
      if(res.code === 0){
        for(let i in res.data){
          if(res.data[i].type === 1){
            this.enterprise.push(res.data[i]);
          }else if(res.data[i].type === 2){
            this.usage.push(res.data[i]);
          }else if(res.data[i].type === 3){
            this.friendLink.push(res.data[i]);
          }
        }
      }
    });
  }
}
</script>

<style lang="less">
  .foot{
    background-color:#fff;
    margin-top:50px;
    overflow: hidden;
    .split_line{
      border-top:1px solid #dcdcdc;
      margin-top:70px;
    }
    .content{
      display:flex;
      margin-top:70px;
      justify-content: space-between;
      .foot_logo{
        display:flex;
        align-items: center;
        margin-bottom:35px;
        .logo_txt{
          font-size: 23px;
          letter-spacing: 0;
          color: #1a1a1a;
          margin-left:10px;
        }
      }
      dl{
        line-height: 12px;
        margin-bottom:20px;
        font-size: 14px;
        display:flex;
        dt{
          color: #1a1a1a;
          font-weight: bold;
        }
      }
      .foot_right{
        display:flex;
        .service{
          width:180px;
          .service_title{
            font-size: 16px;
            line-height: 20px;
            color: #1a1a1a;
            margin-bottom:25px;
            text-align: left;
          }
          .service_item{
            display:block;
            text-align:left;
            margin-bottom:20px;
            font-size: 14px;
            line-height: 15px;
            color: #999999;
            text-decoration: none;
          }
        }
      }
    }
    .foot_right{
      display: block;
      margin-top:50px;
      text-align: center;
      font-size: 13px;
      padding-bottom:50px;
      color: #999999;
      a{
        color:#999999;
        text-decoration: none;
      }
    }
    .foot_link_list{
      display:flex;
      margin-top:40px;
    }
    .foot_link{
      width:43px;
      height:43px;
      cursor:pointer;
      border-radius: 43px;
      background-color:#faf9f9;
      margin-right:10px;
      display:flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
