<template>
  <div class="main">
    <top-bar></top-bar>
    <router-view></router-view>
    <foot></foot>
    <el-backtop :right="100" :bottom="100" />
  </div>
</template>

<script>
import topBar from "@/components/topBar";
import foot from "@/components/foot";
import { mapStores } from 'pinia';
import { useUserStore } from "@/store/user/user";
import { useCompanyStore } from "@/store/company/company";
export default {
  name: 'App',
  components: {
    topBar,foot
  },
  mounted(){
    if(window.location.href.indexOf("token") === -1){
      //浏览器自带token 则不自动登陆
      if(localStorage.getItem("JOB_USER_TOKEN")){
        this.userStore.getUserInfo();
      } else if(localStorage.getItem("JOB_COMPANY_TOKEN")){
        this.companyStore.getCompanyAccount();
      }
    }
  },
  computed:{
    ...mapStores(useUserStore,useCompanyStore)
  }
}
</script>
<style lang="less">
 @import "./assets/css/layout.less";
</style>
