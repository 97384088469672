<template>
<div class="top_bar">
  <div class="content">
    <div style="    display: flex;align-items: center;">
      <div class="logo_wrapper">
        <img class="logo" src="../assets/img/index_logo.png"/>
<!--        <div class="logo_txt">嵌入式人才</div>-->
      </div>

      <div class="top_menu">
<!--        <div class="menu_item" @click="goRoute('/')" :class="{menu_item_cur:$route.path === '/'}">首页</div>-->
<!--        <div class="menu_item" @click="goRoute('/job')" :class="{menu_item_cur:$route.path === '/job'}">职位</div>-->
<!--        <div class="menu_item" @click="goRoute('/company')">公司</div>-->
        <router-link class="menu_item" to="/" :class="{menu_item_cur:$route.path === '/' || $route.path === '/article'}">首页</router-link>
        <router-link class="menu_item" to="/job" :class="{menu_item_cur:$route.path === '/job' || $route.path === '/jobDetail'}">职位</router-link>
        <router-link class="menu_item" to="/company_list" :class="{menu_item_cur:$route.path === '/company_list' || $route.path === '/companyDetail'}">公司</router-link>
      </div>
    </div>


    <div class="login_user" v-if="userStore.isLogin">
<!--      <div class="alarm_tip">-->
<!--        <el-badge :value="8" :max="99" class="item">-->
<!--          <img src="../assets/img/alarm.png"/>-->
<!--        </el-badge>-->
<!--      </div>-->
      <div class="welcome" @click="goRoute('/resume')">您好，{{userStore.userInfo.user && userStore.userInfo.user.realName}}</div>
      <el-dropdown @command="handlerDropMenu">
          <span class="el-dropdown-link">
             <div class="photo">
                <router-link to="/resume">
                  <img :src="userStore.userInfo.user.avatarUrl"/>
                </router-link>
             </div>
            <img class="triangle"  src="../assets/img/icon_triangle.png"/>
          </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="exit">退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="login_user" v-else-if="companyStore.isLogin">
      <div class="welcome" @click="goRoute('/person','company')">您好，{{companyStore.companyAccount.realName}}</div>
      <div class="photo" @click="goRoute('/person','company')">
        <img :src="companyStore.companyAccount.avatarUrl"/>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapStores } from 'pinia';
import { useUserStore } from "@/store/user/user";
import { useCompanyStore } from "@/store/company/company";
import CONSTANTS from "@/api/user/constant";
import {getTokenByS} from "@/api/user/user";
export default {
  name: "topBar",
  data(){
    return {
    }
  },
  methods:{
    goRoute(url,act = "user"){
      if(url===this.$route.path){
        return ;
      }
      if(act === "company"){
        window.location.href=CONSTANTS.COMPANY_URL+"#"+url
      }else{
        this.$router.push(url);
      }
    },
    handlerDropMenu(v){
      if(v === "exit"){
        this.userStore.logout();
        this.$router.push("/");
      }
    },
    getTokenS(){
      getTokenByS({
        token:this.$route.query.token
      }).then(res => {
        if(res.code === 0 && res.data){
          localStorage.setItem("JOB_USER_TOKEN",res.data.token);
          localStorage.setItem("JOB_USER_REFRESH_TOKEN",res.data.refreshToken);
          window.location.href = "/";
        }
      });
    }
  },
  mounted(){
    this.userStore.getIndustryList();
    this.userStore.getPositionList();
    if(this.$route.query.token !== undefined){
      this.getTokenS();
    }
  },
  watch:{
    "$route.query":function (v){
      if(v.token !== undefined){
         this.getTokenS();
      }
    }
  },
  computed:{
    ...mapStores(useUserStore,useCompanyStore)
  }
}
</script>

<style lang="less">
  .top_bar{
    height: 64px;
    background-color: #212329;
    .login_user{
      display:flex;
      align-items: center;
      .el-dropdown{
        height:45px;
        .el-dropdown-link{
          outline:none;
          display:flex;
          align-items: center;
        }
        //&:hover{
        //  .triangle{
        //    transform: rotate(180deg);
        //  }
        //}
      }
      .alarm_tip{
        cursor:pointer;
        img{
          width:19px;
          height:25px;
        }
        .el-badge__content{
          border:none;
        }
      }
      .photo{
        width:45px;
        height:45px;
        margin-left:10px;
        cursor:pointer;
        img{
          width:100%;
          height:100%;
          border-radius: 45px;
        }
      }
      .welcome{
        margin-left:20px;
        font-size: 15px;
        color: #ffffff;
        cursor:pointer;
      }
    }
    .content{
      height:64px;
      display:flex;
      align-items: center;
      justify-content: space-between;
      .logo_wrapper{
        display:flex;
      }
      .logo{
        //width:41px;
        //height:39px;
        height:45px;
      }
      .logo_txt{
        margin-left:10px;
        font-size: 22px;
        letter-spacing: 0;
        line-height:39px;
        color: #ffffff;
      }
      .top_menu{
          display:flex;
        margin-left:80px;
      }
      .menu_item{
        cursor:pointer;
        font-size: 18px;
        line-height: 63px;
        color: #ffffff;
        margin-left:50px;
        text-decoration: none;
      }
      .menu_item_cur{
        color: #00b0b1;
      }
    }
  }
</style>
