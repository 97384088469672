import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import { createPinia } from 'pinia'

import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
createApp(App)
    .use(ElementPlus,{
        locale: zhCn,
    })
    .use(createPinia())
    .use(router).mount('#app')
