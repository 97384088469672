import { defineStore } from 'pinia';
// import {useUserStore} from "@/store/user/user";
// import { login,getMsgCode,getUserInfo,getIndustryList,getPositionList} from "@/api/user/user";
import {login,getCompanyInfo,getCompanyAccount} from "@/api/company/company";
// import { ElMessage } from "element-plus";
export const useCompanyStore = defineStore({
    id:"company",
    state(){
        return {
            companyInfo:{

            },
            companyAccount:{

            }
        }
    },
    getters:{
        isLogin: (state) => {
            return state && state.companyAccount && Object.keys(state.companyAccount).length > 0
        },
    },
    actions:{
        login(data){
            return login(data);
        },
        exit(){
            localStorage.removeItem("JOB_COMPANY_TOKEN");
            localStorage.removeItem("JOB_COMPANY_REFRESH_TOKEN");
        },
        getCompanyInfo(){
            getCompanyInfo().then(res => {
                this.companyInfo = res.data;
            });
        },
        getCompanyAccount(){
            getCompanyAccount().then(res => {
                if(res.code === 0){
                    this.companyAccount = res.data;
                    this.getCompanyInfo();
                }
                // res.data.userType = "company";
                // useUserStore.setUserInfo(res.data);
            });
        }
    }
})
