import axios from "axios";
// import qs from "qs";
import { ElMessage } from 'element-plus';
// import {refreshCompanyToken} from "@/api/company/company";
// import {refreshToken} from "@/api/user/user";

axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
// axios.defaults.headers.Authorization = localStorage.getItem("TOKEN")
//     ? localStorage.getItem("TOKEN")
//     : "";
// 添加请求拦截器

// 添加响应拦截器
axios.interceptors.response.use(
    function (response) {
        // 对响应数据做点什么
        return response;
    },
    function (error) {
        // 对响应错误做点什么
        // eslint-disable-next-line no-undef
        // ElementPlus.Message({
        //     message: "网络错误",
        //     type: "error",
        // });
        ElMessage({
            message: "网络错误",
            type: "error",
        });
        return Promise.reject(error);
    }
);
const request = ({ method = "get", url, params = {},act='json',token_type='job_token'}) => {
    return new Promise((resolve, reject) => {
        if (method === "post") {
            let headers = {
                "Content-type": act === "json"?"application/json" : "application/x-www-form-urlencoded",
            };
            if(token_type === "job_token"){
                headers['job-user-token'] = localStorage.getItem("JOB_USER_TOKEN");
            }else if(token_type === "refresh_uer_token"){
                headers['job-user-token'] = localStorage.getItem("JOB_USER_REFRESH_TOKEN")
            }else if(token_type === "company"){
                headers['job-company-token'] = localStorage.getItem("JOB_COMPANY_TOKEN");
            }else{
                headers['job-company-token'] = localStorage.getItem("JOB_COMPANY_REFRESH_TOKEN");
            }
            //act === "json"?params:qs.stringify()
            url = '/job'+url;
            axios
                .post(url, params, {
                    headers: headers,
                })
                .then(function (response) {
                    if(response.data.code === -10){
                        // window.location.href="/";
                        //
                        // if(token_type === "company"){
                        //     refreshCompanyToken().then(res => {
                        //         if(res.code === 0){
                        //             localStorage.setItem("JOB_COMPANY_TOKEN",res.data.token);
                        //             localStorage.setItem("JOB_COMPANY_REFRESH_TOKEN",res.data.token);
                        //             // window.location.reload(true);
                        //         }else{
                        //             window.location.href = "/";
                        //         }
                        //     })
                        // }else{
                        //     refreshToken().then(res => {
                        //         if(res.code === 0){
                        //             localStorage.setItem("JSON_USER_TOKEN",res.data.token);
                        //             localStorage.setItem("JOB_USER_REFRESH_TOKEN",res.data.token);
                        //             // window.location.reload(true);
                        //         }else{
                        //             window.location.href = "/";
                        //         }
                        //     });
                        // }

                    }
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        } else {
            axios
                .get(url, {
                    params: params,
                })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        }
    });
};

export default request;
