// import Vue from "vue";
import { createRouter, createWebHashHistory } from 'vue-router'
// Vue.use(Router);
const Home = ()=>import("@/pages/home");
const job = ()=>import("@/pages/job");
const companyDetail = ()=>import("@/pages/companyDetail");
const jobDetail = ()=>import("@/pages/jobDetail");
const content = ()=>import("@/pages/content");
const resumeEdit = ()=>import("@/pages/resumeEdit");
const companyList = ()=>import("@/pages/companyList");
// const article = ()=>import("@/pages/article");

// const admin = ()=>import("@/companyPages/pages/adminIndex");
const myPosition = ()=>import("@/companyPages/pages/myPosition");
const editPosition = ()=>import("@/companyPages/pages/editPosition");
const candidateList = ()=>import("@/companyPages/pages/candidateList");
const recommendList = ()=>import("@/companyPages/pages/recommendList");
const resume = ()=>import("@/companyPages/pages/resume");
const person = ()=>import("@/companyPages/pages/person");
const companyInfo = ()=>import("@/companyPages/pages/companyInfo");


const scrollBehavior = (to,from,savedPosition)=>{
    if(savedPosition && to.meta.keepAlive){
        return savedPosition;
    }
    return {
        left:0,top:0
    }
}
const router=createRouter({
    history:createWebHashHistory(),
    scrollBehavior,
    routes:[
        {
            path: "/",
            name: "home",
            component: Home,
            meta:{
                title:"首页",
            }
        },{
            path:"/job",
            name:"job",
            component:job,
            meta:{
                title:"职位",
            }
        },{
            path:"/companyDetail",
            name:"companyDetail",
            component:companyDetail
        },{
            path:"/jobDetail",
            name:"jobDetail",
            component:jobDetail,
            meta:{
                title:"职位详情"
            }
        },{
            path:"/article/:id",
            name:"content",
            component:content
        },{
            path:"/resume",
            name:"resumeEdit",
            component:resumeEdit,
            meta:{
                title:"简历"
            }
        },{
            path:"/company_list",
            name:"companyList",
            component:companyList,
            meta:{
                title:"公司"
            }
        },{
            path:"/admin",
            name:"admin",
            component: myPosition
        },{
            path:"/myPosition",
            name:"myPosition",
            component: myPosition
        },{
            path:"/editPosition",
            name:"editPosition",
            component: editPosition
        },{
            path:"/candidateList",
            name:"candidateList",
            component: candidateList
        },{
            path:"/recommendList",
            name:"recommendList",
            component:recommendList
        },{
            path:"/resumeDetail",
            name:"resumeDetail",
            component:resume
        },{
            path:"/person",
            name:"person",
            component:person
        },{
            path:"/companyInfo",
            name:"companyInfo",
            component:companyInfo
        }
    ]
});

router.beforeEach((to,from,next) => {
    if(to.meta && to.meta.title){
        document.title = to.meta.title;
    }
    // window.scrollTo(0, 0)
    next();
})
export default router;
